define("shop/templates/components/gift-card/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jfAxyvCO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"px-12 lg:py-24\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flex flex-col lg:flex-row items-center max-w-3xl mx-auto\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-3/4 lg:w-1/2 mb-8 lg:mb-0\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"w-full pt-1/1 relative pointer-events-none\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"/images/11.jpg\"],[10,\"class\",\"block absolute pin rounded-full\"],[10,\"alt\",\"\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"w-full lg:w-1/2 mb-8 lg:mb-0 text-center\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[10,\"class\",\"font-medium text-2xl lg:text-4xl tracking-tight mb-2 lg:mb-4\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"giftCard.header.title\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"max-w-xs mx-auto text-lg lg:text-xl lg:tracking-tight mb-6 lg:mb-8\"],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"giftCard.header.subtitle\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mx-auto\"],[8],[0,\"\\n        \"],[7,\"button\",false],[12,\"class\",\"btn lg:btn--lg\"],[3,\"action\",[[23,0,[]],[24,[\"select\"]],\"virtual\"]],[8],[0,\"\\n          \"],[1,[28,\"t\",[\"giftCard.btn.shopNow\"],null],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"mt-8\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"rel\",\"query\"],[\"border-b border-black pb-1\",\"noindex\",[28,\"hash\",null,[[\"help\"],[\"giftCard\"]]]]],{\"statements\":[[0,\"            \"],[1,[28,\"t\",[\"giftCard.btn.faq\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "shop/templates/components/gift-card/header.hbs"
    }
  });

  _exports.default = _default;
});